import React, { Suspense } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'

import Error404 from './components/404'
import OrderView from './components/orders/view'
import ProductIndex from './components/products/index'

function App() {
    const routes = [
        {
            path: '/products',
            view: ProductIndex
        },
        {
            path: '/order/:id',
            view: OrderView
        },
        {
            path: '/',
            view: Error404
        },
    ]

    return (
        <Suspense fallback="loading">
            <main className="App">
                <Router>
                    <Switch>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={() => <route.view />}
                            />
                        ))}
                    </Switch>
                </Router>
            </main>
        </Suspense>
    )
}

export default App
