import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import configs from '../../configs'

export default function ProductIndex() {
    const history = useHistory()
    const [products, setProducts] = useState([])

    useEffect(() => {
        axios.get(`${configs.backend_url}/products`)
            .then(res => res.data).then(data => setProducts(data))
    }, [])

    function createOrder(data) {
        return axios.post(`${configs.backend_url}/orders`, data).then(res => res.data)
    }

    function createKPayQROrder(product) {
        createOrder({
            pid: product.id,
            currency: 'MMK',
            method: 'kbzPayQR',
            next: 'ttkeyboard://'
        }).then(order => {
            history.push(`/order/${order.id}`)
        })
    }

    function createKPayWebOrder(product) {
        createOrder({
            pid: product.id,
            currency: 'MMK',
            method: 'kbzPayWeb',
            next: 'ttkeyboard://'
        }).then(order => {
            history.push(`/order/${order.id}`)
        })
    }

    return (
        <section>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th colSpan="2">Create order</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => {
                        return (
                            <tr key={product.id}>
                                <td>{product.id}</td>
                                <td>{product.title}</td>
                                <td>{JSON.stringify(product.priceList)}</td>
                                <td><button onClick={() => createKPayQROrder(product)}>KPay QR</button></td>
                                <td><button onClick={() => createKPayWebOrder(product)}>KPay Web</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </section >
    )
}
