import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import axios from 'axios'
import poll from '../../utils/poll'
import { millisecondsToString } from '../../utils'
import configs from '../../configs'
import './view.css'

export default function OrderView() {
    const { t, i18n } = useTranslation()
    const orderID = useParams().id
    const [order, setOrder] = useState({})
    const [hasOrder, setHasOrder] = useState(false)
    const [isDark, setIsDark] = useState(false)
    const [orderNotFound, setOrderNotFound] = useState(false)
    const [isUnknownError, setIsUnknownError] = useState(false)

    function onMediaQueryEvent(q) {
        if (q.matches) {
            setIsDark(true)
        } else {
            setIsDark(false)
        }
    }

    useEffect(() => {
        if (window.matchMedia) {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
            mediaQuery.addListener(onMediaQueryEvent)
            onMediaQueryEvent(mediaQuery)
            return function () {
                mediaQuery.removeListener(onMediaQueryEvent)
            }
        }
    }, [])

    useEffect(() => {
        let stop = false
        poll({
            fn: () => {
                return axios
                    .get(`${configs.backend_url}/orders/${orderID}`)
                    .then(res => res.data)
                    .catch(err => {
                        if (err.response) {
                            if (err.response.status === 404) {
                                setOrderNotFound(true)
                                return
                            }
                        }
                        setIsUnknownError(true)
                    })
            },
            validate: (order) => {
                if (!order) { return true }
                if (stop) { return true }

                setOrder(order)
                setHasOrder(true)
                return !isPayable(order) || isOrderExpired(order)
            },
            interval: 1000,
        })
        return function () {
            stop = true
        }
    }, [orderID])

    function changeLanguage(lang) {
        i18n.changeLanguage(lang)
    }

    function isOrderExpired(anotherOrder) {
        const _order = anotherOrder || order
        if (_order.status === 'waitingForPayment') {
            return new Date().getTime() > new Date(_order.dueDate).getTime()
        }
        return false
    }

    function isQRPayment() {
        return order.storage.qrCode !== undefined
    }

    function getMessage() {
        const messages = {
            'created': t('orders.messages.order-created'),
            'waitingForPayment': isQRPayment() ? t('orders.messages.scan-qr') : t('orders.messages.click-to-pay'),
            'paymentFailed': t('orders.messages.order-payment-failed'),
            'paymentSuccess': t('orders.messages.order-paid'),
        }
        let message = messages[order.status]
        if (isOrderExpired()) {
            message = t('orders.messages.order-timeout')
        }
        return message
    }

    function getStatusText() {
        const messages = {
            'created': t('orders.states.created'),
            'waitingForPayment': t('orders.states.waiting'),
            'paymentFailed': t('orders.states.failed'),
            'paymentSuccess': t('orders.states.success'),
        }
        let message = messages[order.status]
        if (isOrderExpired()) {
            message = t('orders.states.timeout')
        }
        return message
    }

    function isPayable(anotherOrder) {
        const _order = anotherOrder || order
        return _order.status === 'waitingForPayment' && !isOrderExpired()
    }

    function isSuccess() {
        return order.status === 'paymentSuccess'
    }

    function canContinue() {
        return isSuccess() && order.storage.bounceBackUrl
    }

    function onContinue() {
        document.location = order.storage.bounceBackUrl
    }

    function onMakePayment() {
        document.location = `${configs.backend_url}/kpay?id=${order.id}`
    }

    function isKBZPay() {
        return /^kbzPay/.test(order.paymentMethod)
    }

    const timeoutIn = new Date(order.dueDate).getTime() - new Date().getTime()

    return (
        <React.Fragment>
            {isUnknownError && <div className="single-error">{t('orders.errors.unhandled')}</div>}
            {orderNotFound && <div className="single-error">{t('orders.errors.notFound')}</div>}
            <section>
                {hasOrder && <div className="card">
                    <div className="card-content">
                        <h5 className="card-title">{t('orders.status.title')}</h5>
                        {isPayable() && isQRPayment() && <QRCode
                            role='image'
                            aria-label="qr code"
                            className="qrcode"
                            value={order.storage.qrCode}
                            size={256}
                            bgColor={isDark ? '#352f44' : 'white'}
                            fgColor={isDark ? 'white' : '#352f44'} />}
                        <label>{t('orders.labels.name')} <div className="right">{order.product && order.product.title}</div></label>
                        <label>{t('orders.labels.price')} <div className="right">{order.amount} {order.currency}</div></label>
                        <label>{t('orders.labels.status')} <div className="right">{getStatusText()}</div></label>
                        {isPayable() && !isOrderExpired() && <label>{t('orders.labels.expires')} <div className="right">{millisecondsToString(timeoutIn)}</div></label>}
                        {isPayable() && !isQRPayment() && <button onClick={onMakePayment} id="pay" aria-label="pay" className="card-action primary">
                            {isKBZPay() ? t('orders.buttons.openkbzpay') : t('orders.buttons.pay')}
                        </button>}
                        {canContinue() && <button onClick={onContinue} id="action" aria-label="continue" className="card-action primary">{t('orders.buttons.continue')}</button>}
                        <p id="message">{getMessage()}</p>
                        {isPayable() && <div id="spinner" className="spinner"></div>}

                        <div className="sec-lang">
                            <button className="btn-lang" onClick={() => changeLanguage('en')}>English</button>
                            <button className="btn-lang" onClick={() => changeLanguage('my')}>မြန်မာ</button>
                        </div>
                    </div>
                </div>}
            </section>
        </React.Fragment>
    )
}