export function millisecondsToString (milliseconds) {
    // TIP: to find current time in milliseconds, use:
    // var  current_time_milliseconds = new Date().getTime();

    // function numberEnding (number) {
    //     return (number > 1) ? 's' : ''
    // }

    const timeLeft = {
        d: Math.floor(milliseconds / (1000 * 60 * 60 * 24)),
        h: Math.floor((milliseconds / (1000 * 60 * 60)) % 24),
        m: Math.floor((milliseconds / 1000 / 60) % 60),
        // second: Math.floor((milliseconds / 1000) % 60)
    }

    let components = []

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return
        }
        
        components.push(`${timeLeft[interval]}${interval}`)
    })

    return components.join(':')
}