import React from 'react'

export default function Error404() {
    return (
        <React.Fragment>
            <div className="single-error">
                <p>404!</p>
            </div>
        </React.Fragment>
    )
}