import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translations/en/translation.json'
import my from './translations/my/translation.json'

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: {
                translation: en
            },
            my: {
                translation: my
            }
        },
        lng: 'my',
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    })


export default i18n